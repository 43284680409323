import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router";
import "rsuite/dist/rsuite.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import "./App.css";
import Certificate from "./pages/certificate"
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import TechSup from "./components/TechSup/TechSup";
import AllCourses from "./pages/AllCourses/AllCourses";
import Books from "./pages/Books/Books";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/Contact/Contact";
import CourseDetails from "./pages/CourseDetails/CourseDetails";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Profile from "./pages/Profile/Profile";
import ProfileUnits from "./pages/Profile/ProfileUnits/ProfileUnits";
import ProfileVideo from "./pages/Profile/ProfileUnits/ProfileVideo/ProfileVideo";
import Registration from "./pages/Registration/Registration";
import Units from "./pages/Unit/Units";
import "rsuite/dist/rsuite.min.css";

// import ExpandList from './components/ExpandList/ExpandList';
import "react-toastify/dist/ReactToastify.css";
// import { ToastContainer } from 'react-bootstrap';
import axios from "axios";
import { Object } from "core-js";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer } from "react-toastify";
import EnrollNow from "./components/enroll-course";
import VideoLoader from "./components/loader";
import { base_url } from "./constants";
import "./newStyle.css";
import CourseQuestions from "./pages/CourseQuestions/CourseQuestions";
import Registration2 from "./pages/Registration2/Registration2";
import VideoQuestions from "./pages/VideoQuestions/VideoQuestions";
import CourseContent from "./pages/courseVideos";
import MyCourses from "./pages/mycourses/AllCourses";
import Subscribe from "./pages/subscribe";
import AnimatedTooltipButton from "./components/helpcenter";
import AllBooks from "./pages/allBooks";
import SubscribeBooks from "./pages/subscribebook";
import MyBooks from "./layouts/home/books/myBooks";
import Book from "./pages/Book/Books";
import About from "./pages/Home/about";
import AttachmentViewer from "./pages/Book/attachmentViewer";
import PaymentForm from "./pages/gatewayp/payment";
import BooksIframe from "./pages/Book/booksIframe";
const localData = localStorage.getItem("elmataryapp");
const decryptedBytes = localData && CryptoJS.AES.decrypt(localData, "111");
const userData =
  decryptedBytes && JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
export const handleLogOut = async () => {
  const data_send = {
    student_id: userData?.student_id,
    token_value: userData?.token_value,
  };

  axios
    .post(base_url + "/user/auth/student_logout.php", JSON.stringify(data_send))
    .then(async (res) => {
      if (res.data.status == "success") {
        localStorage.clear();
        window.location.reload();
      } else if (res.data.status == "error") {
      } else if (res.data.status == "out") {
        await handleLogOut();
        localStorage.clear();
        window.location.reload();
      }
    })
    .catch((e) => console.log(e));

  // window.location.reload()
};
function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // if (userData) refreshToken();
  }, [pathname, userData]);

  const [allLoading, setAllLoading] = useState(true);
  return (
    <>
      <>
        {/* {!allLoading ? ( */}
        <>
          <AnimatedTooltipButton />
          <Header />

          <Routes>
            {userData && Object.keys(userData).length > 0 ? (
              <>
                <Route path="/book" element={<Books />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/units" element={<Units />} />
                <Route path="/EnrollNow" element={<EnrollNow />} />
                <Route path="/About" element={<About />} />
                <Route path="/certificate/:id" element={<Certificate />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/profileunits" element={<ProfileUnits />} />
                <Route path="/lessonvideo" element={<ProfileVideo />} />
                <Route path="/allcourses" element={<AllCourses />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/techsup" element={<TechSup />} />
                <Route path="/MyCourses" element={<MyCourses />} />
                <Route path="/Subscribe" element={<Subscribe />} />
                <Route path="/SubscribeBooks" element={<SubscribeBooks />} />
                <Route path="/MyBooks" element={<MyBooks />} />
                <Route path="/ebook" element={<Book />} />
                <Route path="/BooksIframe" element={<BooksIframe />} />

                <Route path="/images" element={<AttachmentViewer />} />
                <Route path="/PaymentForm" element={<PaymentForm />} />
                {/* <Route path="/AllBooks" element={<AllBooks />} /> */}
                <Route path="/AllBooks" element={<AllBooks />} />
                {/* MyCourses */}
                <Route path="/coursequestions" element={<CourseQuestions />} />
                <Route path="/coursedetails" element={<CourseDetails />} />
                <Route path="/videoquestions" element={<VideoQuestions />} />
                <Route path="*" element={<Home />} />
                <Route path="/CourseContent" element={<CourseContent />} />
                {/* CourseContent */}
              </>
            ) : (
              <>
                <Route index path="/" element={<Home />} />
                {/* <Route path='/allcourses' element={<AllCourses/>}/> */}
                <Route path="/signup" element={<Registration />} />
                <Route path="/signup2" element={<Registration2 />} />
                <Route path="/certificate/:id" element={<Certificate />} />
                <Route path="/login" element={<Login />} />
                <Route path="/About" element={<About />} />
                <Route path="/book" element={<Books />} />
                <Route path="/techsup" element={<TechSup />} />
                <Route path="*" element={<Home />} />
              </>
            )}
            {/* <Route path='/expand' element={<ExpandList/>}/> */}
          </Routes>
          <ToastContainer />
          <Footer />
        </>
        {/* ) : (
          <VideoLoader setAllLoading={setAllLoading} allLoading={allLoading} />
        )} */}
      </>
      {/* <ExternalRedirectGuard>

    </ExternalRedirectGuard> */}
    </>
  );
}

export default App;
