import React, { useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import "./banner2.css";
import CryptoJS from "crypto-js";
import bcrypt from "bcryptjs";

import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router";
const salt = bcrypt.genSaltSync(10);
const Banner2 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // secureLocalStorage.setItem("object", {
    //     message:  "This is testing of local storage",
    // });
    // secureLocalStorage.setItem("number", 12);
    // secureLocalStorage.setItem("string", "12");
    // secureLocalStorage.setItem("boolean", true);
    // let value = secureLocalStorage.getItem("boolean");
  }, []);
  const handleOpenCrypt = () => {
    const encryptedData = localStorage.getItem("elmataryapp");
    if (encryptedData) {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, "111");
      const decryptedData = JSON.parse(
        decryptedBytes.toString(CryptoJS.enc.Utf8)
      );
    }
  };
  const localData = localStorage.getItem("elmataryapp");
  const decryptedBytes = localData && CryptoJS.AES.decrypt(localData, "111");
  const userData =
    decryptedBytes && JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));

  return (
    <section className="index-banner">
      <div className="banner2">
        {/*
        <button
        onClick={()=>{
          let pushedData=bcrypt.hashSync(JSON.stringify({first_name:'abdu',last_name:'ali',email:'aa032@gmail.com'}),"$2a$10$CwTycUXWue0Thq9StjUM0u");
          const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({first_name:'abdu',last_name:'ali',email:'aa032@gmail.com'}), '111').toString();
          localStorage.setItem('elmataryapp', encryptedData);
          // localStorage.setItem("tryhash",pushedData);
          // localStorage.setItem('elmataryapp',JSON.stringify({first_name:'abdu',last_name:'ali',email:'aa032@gmail.com'}))
        }}
      >push to local</button>
      <button
        onClick={()=>{
          handleOpenCrypt()
        }}
      >open encrypt</button>
      */}
        {/* <a href="https://facebook.com">ew</a> */}
        <div className="bannerOverLay"></div>
        <Slide className="left" direction="left">
          <div>
            <h3 style={{ display: "flex", alignItems: "flex-start" }}>
              <img
                src="https://res.cloudinary.com/duovxefh6/image/upload/v1709453255/image-removebg-preview_gzd77w.png"
                alt=""
                width={200}
              />
              <span>
                {" "}
                Welcome To Elmatary Etablishment For Online Surgery Courses
              </span>
            </h3>
            <h4>Unloce Your Potenial, Master Your Future!</h4>
            {/* <h3>Welcome To Our Future Doctors</h3> */}
            <p>Ready? Let’s Make your learning experience extraordinary.</p>
            <div className="actions">
              <button
                onClick={() => {
                  return (
                    (userData &&
                      userData?.student_id &&
                      navigate("/profile")) ||
                    navigate("/login")
                  );
                }}
              >
                Get Started Now!
              </button>
              <button
                onClick={() => {
                  // navigate("/profile");
                }}
              >
                View A Demo
              </button>
            </div>
          </div>
        </Slide>
        <Slide className="right" direction="right">
          <div style={{ height: "100%" }}>
            <img
              src={
                "https://res.cloudinary.com/duovxefh6/image/upload/v1709382064/image8_fc50r7.png"
              }
              alt=""
            />
          </div>
        </Slide>
      </div>
    </section>
  );
};

export default Banner2;
