import React from 'react';
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { star } from '../../../assets/svgIcons';

const StoryContainer = () => {
  return (
    <div className="stories_container">
      <img
        src="https://res.cloudinary.com/duovxefh6/image/upload/v1709719285/5d1cd962-c032-490e-978e-3b82e4461927.png"
        alt=""
        // width={400}
        height={440}
      />
      <Swiper
        // install Swiper modules
        slidesPerView="1"
        mousewheel={true}
        direction="vertical"
        modules={[Pagination]}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
      >
        <SwiperSlide>
          <div className="story-container">
            <div className="story">
              <StoryHeader />
              <StoryEvaluate />
              <StoryContent />
              <StudentInfo />
            </div>
            <img
              src="https://res.cloudinary.com/duovxefh6/image/upload/v1709729335/Screenshot_2024-03-06_125701-removebg-preview_lzfrg7.png"
              alt=""
              width={100}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="story-container">
            <div className="story">
              <StoryHeader />
              <StoryEvaluate />
              <StoryContent />
              <StudentInfo />
            </div>
            <img
              src="https://res.cloudinary.com/duovxefh6/image/upload/v1709729335/Screenshot_2024-03-06_125701-removebg-preview_lzfrg7.png"
              alt=""
              width={100}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="story-container">
            <div className="story">
              <StoryHeader />
              <StoryEvaluate />
              <StoryContent />
              <StudentInfo />
            </div>
            <img
              src="https://res.cloudinary.com/duovxefh6/image/upload/v1709729335/Screenshot_2024-03-06_125701-removebg-preview_lzfrg7.png"
              alt=""
              width={100}
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

const StoryHeader = () => {
  return (
    <h1 className="story-header">
      What’s our Real Student StoriesAbout Our Work& Passion?
    </h1>
  );
};
const StoryEvaluate = () => {
  return (
    <div className="stars">
      {" "}
      {Array(5)
        .fill(0)
        .map((item) => {
          return <span className="star">{star}</span>;
        })}
    </div>
  );
};
const StoryContent = () => {
  return (
    <p className="story-content">
      This course exceeded my expectations in every way! The content was
      comprehensive, the instructor was engaging and knowledgeable, and the
      interactive activities really enhanced my learning experience. I feel
      confident and equipped to apply what I've learned in my future endeavors.
      Highly recommend!
    </p>
  );
};

const StudentInfo = () => {
  return (
    <div className="student-info">
      <p>Tarek Hamdy</p>
      <p>Ain Shams University, 5th Year</p>
    </div>
  );
};

export default StoryContainer;
