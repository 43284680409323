import React, { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #333;
`;

const Subtitle = styled.p`
  font-size: 1.2em;
  color: #777;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 2em;
  color: #333;
  display: flex;
  align-items: center;
`;

const SectionText = styled.p`
  font-size: 1.1em;
  color: #555;
  line-height: 1.6;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: 1.1em;
  color: #555;
  line-height: 1.6;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ContactInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ContactInfoItem = styled.div`
  flex: 1 1 30%;
  margin-bottom: 20px;
`;

const ContactTitle = styled.h3`
  font-size: 1.5em;
  color: #333;
  display: flex;
  align-items: center;
`;

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Container>
      <Header>
        <Title>About Us</Title>
        <Subtitle>
          Welcome to Dr. El Matary's Online Training, your premier comprehensive
          medical education and professional development destination.
        </Subtitle>
      </Header>
      <Section>
        <SectionTitle>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 3l9.5 7.5l-9.5 7.5l-9.5 -7.5z" />
            <path d="M12 3v18" />
            <path d="M3 12l9 3l9 -3" />
          </svg>
          Our Story
        </SectionTitle>
        <SectionText>
          Dr. El Matary's Online Training was born from a vision to bridge the
          gap in medical education. Recognizing the need for flexible and
          engaging learning solutions, Dr. El Matary established this platform
          to empower learners with the knowledge and skills essential for
          excelling in their medical careers. Over the years, we have built a
          reputation for delivering top-notch educational content, earning the
          trust and satisfaction of countless students and professionals.
        </SectionText>
      </Section>
      <Section>
        <SectionTitle>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 3l9.5 7.5l-9.5 7.5l-9.5 -7.5z" />
            <path d="M12 3v18" />
            <path d="M3 12l9 3l9 -3" />
          </svg>
          What We Offer
        </SectionTitle>
        <List>
          <ListItem>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 3l9.5 7.5l-9.5 7.5l-9.5 -7.5z" />
              <path d="M12 3v18" />
              <path d="M3 12l9 3l9 -3" />
            </svg>
            Undergraduate programs
          </ListItem>
          <ListItem>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 3l9.5 7.5l-9.5 7.5l-9.5 -7.5z" />
              <path d="M12 3v18" />
              <path d="M3 12l9 3l9 -3" />
            </svg>
            Specialized training for MRCS exams
          </ListItem>
          <ListItem>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 3l9.5 7.5l-9.5 7.5l-9.5 -7.5z" />
              <path d="M12 3v18" />
              <path d="M3 12l9 3l9 -3" />
            </svg>
            Offline and live lectures
          </ListItem>
          <ListItem>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 3l9.5 7.5l-9.5 7.5l-9.5 -7.5z" />
              <path d="M12 3v18" />
              <path d="M3 12l9 3l9 -3" />
            </svg>
            Extensive question banks
          </ListItem>
          <ListItem>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 3l9.5 7.5l-9.5 7.5l-9.5 -7.5z" />
              <path d="M12 3v18" />
              <path d="M3 12l9 3l9 -3" />
            </svg>
            Mock exams
          </ListItem>
          <ListItem>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 3l9.5 7.5l-9.5 7.5l-9.5 -7.5z" />
              <path d="M12 3v18" />
              <path d="M3 12l9 3l9 -3" />
            </svg>
            Personalized learning plans
          </ListItem>
        </List>
      </Section>
      <Section>
        <SectionTitle>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 3l9.5 7.5l-9.5 7.5l-9.5 -7.5z" />
            <path d="M12 3v18" />
            <path d="M3 12l9 3l9 -3" />
          </svg>
          Our Commitment
        </SectionTitle>
        <SectionText>
          At Dr. El Matary's Online Training, we believe in the power of
          education to transform lives. Our team of expert educators, led by Dr.
          El Matary, is dedicated to providing unparalleled support and
          guidance, helping students achieve their academic and professional
          goals. Join our community and embark on a journey of learning and
          growth with us.
        </SectionText>
      </Section>
      <ContactInfo>
        <ContactInfoItem>
          <ContactTitle>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 3l9.5 7.5l-9.5 7.5l-9.5 -7.5z" />
              <path d="M12 3v18" />
              <path d="M3 12l9 3l9 -3" />
            </svg>
            Company Name
          </ContactTitle>
          <SectionText>DR.Elmatary LLC</SectionText>
        </ContactInfoItem>
        <ContactInfoItem>
          <ContactTitle>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 3l9.5 7.5l-9.5 7.5l-9.5 -7.5z" />
              <path d="M12 3v18" />
              <path d="M3 12l9 3l9 -3" />
            </svg>
            US Address
          </ContactTitle>
          <SectionText>2201 MENAUL BLVD NE, STE A, ALBUQUERQUE, NM 87107</SectionText>
        </ContactInfoItem>
        <ContactInfoItem>
          <ContactTitle>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 3l9.5 7.5l-9.5 7.5l-9.5 -7.5z" />
              <path d="M12 3v18" />
              <path d="M3 12l9 3l9 -3" />
            </svg>
            US Phone Number
          </ContactTitle>
          <SectionText>+1 (872) 308-0246</SectionText>
        </ContactInfoItem>
      </ContactInfo>
    </Container>
  );
};

export default About;
