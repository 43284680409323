import React from "react";
import "./style.css";
const Goal = () => {
  return (
    <div
      className="rowDiv goalSection"
      style={{ display: "flex", justifyContent: "space-around", width: "100%" }}
    >
      <img
        src="https://res.cloudinary.com/duovxefh6/image/upload/v1709453633/a5359063-b03e-43a7-a47d-433a1a886ad2.png"
        alt=""
        width={"45%"}
        style={{ objectFit: "contain" }}
      />
      <div className="leftBox" style={{ width: "45%" }}>
        <div className="textBox">
          <p>What's Our Main Goal?</p>
          <h4>
            {" "}
            Take The Next Step Towards Your Personal And Professional Goals With
            Dr Elmatary Establishment.
          </h4>
          <p>
            Take the next step toward acheving your personal and professional
            aspirations with us. In a world that demands growth and
            adaptability. Our establishment is your trusted Partner on the
            journey to success.
          </p>
        </div>

        <div className="iconsTextBox columnDiv">
          <div className="iconWithText">
            <img
              src="https://res.cloudinary.com/duovxefh6/image/upload/v1709454201/192e4ff7-676f-4f27-afce-098201527468.png"
              width={100}
              alt=""
            />
            <div className="text">
              <h3>Learn From Experts</h3>
              <p>
                A Stuff of specialized team of doctors all their aim that to
                help you understanding and gain your marks.
              </p>
            </div>
          </div>
          <div className="iconWithText">
            <img
              src="https://res.cloudinary.com/duovxefh6/image/upload/v1709454201/192e4ff7-676f-4f27-afce-098201527468.png"
              width={100}
              alt=""
            />
            <div className="text">
              <h3>Video Tutorial</h3>
              <p>
                Explaining all the parts of the curriculum by professors
                specialized in their fields!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Goal;
