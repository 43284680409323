import axios from "axios";
import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { base_url } from "./../../constants/index";
import PDFViewer from "./PDFViewer.js";
import "./books.css";
import { Loader } from "rsuite";

const Book = () => {
  const [params] = useSearchParams();
  const localData = localStorage.getItem("elmataryapp");
  const [pdf, setPdf] = useState(null);
  const [attspdf, setattspdf] = useState(null);
  const decryptedBytes = localData && CryptoJS.AES.decrypt(localData, "111");
  const userData =
    decryptedBytes && JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  const navigate = useNavigate();
  const [fileBlob, setFileBlob] = useState(null);
  const getData = () => {
    axios
      .post(base_url + "/user/books/select_book_by_id.php", {
        student_id: userData?.student_id,
        token_value: userData?.token_value,
        book_id: params?.get("id"),
      })
      .then((response) => {
        if (!response?.data?.message?.own) {
          return navigate("/SubscribeBooks");
        }
        setPdf(response?.data?.message);
        const byteCharacters = atob(response?.data?.message.file_buffer); 
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const arrayBuffer = byteArray.buffer;

        setFileBlob(arrayBuffer); // Set the ArrayBuffer instead of Blob
        console.log(arrayBuffer);
      })
      .catch((error) =>{
        setFileBlob("null");
        console.log(error)
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const [pdfName, setPdfName] = useState(null);

  useEffect(() => {
    setattspdf(pdf?.book_att?.student_atts);
    if (pdf) setPdfName(pdf?.book_name);
  }, [pdf]);

  const setUserAttachmentForCurrentPage = (
    type,
    pageNum,
    setVoiceUrl,
    setVoice,
    arr
  ) => {
    if (!attspdf) return null;
    setVoice("Open");
    setVoiceUrl(
      attspdf.filter((att) => parseInt(att.page_number) === pageNum)
        ? attspdf.filter((att) => parseInt(att.page_number) === pageNum)
        : null
    );
  };

  return (
    <div className="books_page">
      {!fileBlob ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="books">
          {pdfName && (
            <PDFViewer
              getData={getData}
              pdfName={pdfName}
              document={fileBlob}
              ann={pdf?.annotation}
              adminAtts={pdf?.book_att?.admin_atts}
              userAtts={attspdf}
              setUserAttachmentForCurrentPage={setUserAttachmentForCurrentPage}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Book;
