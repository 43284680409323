import React from "react";
import "./style.css";
import { AiOutlineShareAlt } from "react-icons/ai";
const MoreFeatures = () => {
  return (
    <>
      <div
        className="rowDiv goalSection MoreFeatures"
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <div className="leftBox" style={{ width: "45%" }}>
          <div className="textBox">
            <p>BECOME AN INSTRUCTOR</p>
            <h1>Join Us& Spread Your Knowledge!</h1>{" "}
            <img
              src="https://res.cloudinary.com/duovxefh6/image/upload/v1709455929/image-removebg-preview_3_c8ql8o.png"
              alt=""
              width={100}
              style={{ marginBottom: "15px" }}
            />
            <span>
              {" "}
              We're excited to invite you to join our team as an instructor!
              Share your expertise, inspire learners, and make an impact in our
              community. Let's shape the future of learning together.
            </span>
          </div>
          <div className="actions">
            <button style={{ display: "none" }}>Get Started Now!</button>
            <button
              className="svgWithText instrButton"
              style={{ margin: "20px 0" }}
            >
              <span>BECOME AN INSTRUCTOR</span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <img
          src="https://res.cloudinary.com/duovxefh6/image/upload/v1709730113/image-removebg-preview_gtbiwy.png"
          alt=""
          width={"300"}
          style={{ objectFit: "contain" }}
        />
      </div>
      <section className="homeRow">
        <div className="triangle1"></div>
        <div className="triangle2"></div>
        <div className="imgBox">
          <div className="img"></div>
          <img
            src={require("./istockphoto-1392528328-170667a-removebg-preview.png")}
            alt="user"
          />
        </div>
        <h4>Affordable Online Courses & Learning Opportunities For You</h4>
        <button>
          <span>Explore All Courses </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
              />
            </svg>
          </span>
        </button>
      </section>
    </>
  );
};

export default MoreFeatures;
